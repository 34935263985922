<template>
  <div class="goodlist">
    <el-container id="el-container">
      <my-header></my-header>
      <el-main id="el-main">
        <div class="goodlist-box">
          <div class="goodlist-cate-box">
            <div class="all-good-list">
              <p class="all-good-list-span">全部商品分类</p>
            </div>
            <div class="all-good-list-right">
              <!-- <p>京东时尚</p>
              <p>美妆馆</p>
              <p>超市</p> -->
            </div>
          </div>
          <div class="search-company">
            <div style="font-size: 12px; color: #282c34; margin-top: 5px">
              全部结果
            </div>
            <img
              class="search-company-img"
              src="../assets/images/catego.png"
              alt=""
            />
            <div class="search-company-box">
              <span class="search-good-fam">关键字 :</span>
              <span style="font-size: 12px; color: red; padding-left: 5px">{{
                name
              }}</span>
              <div>
                <img class="no-img" src="../assets/images/noimg.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 店铺 -->
          <!-- <div class="shai-xuan">
            <div class="company-logo-box">
              <img
                class="company-logo-box-img"
                src="../assets/images/dickies.png"
                alt=""
              />
            </div>
            <div class="company-name-txt">
              <p>Dickies官方旗舰店</p>
            </div>
            <div class="go-shop-home">
              <span class="shop-home-go">进入店铺</span>
            </div>
          </div> -->
          <!-- 店铺结束 -->
          <!-- 分类 -->
          <!-- <div class="shai-xuan-box">
            <div class="box-list-row">
              <div class="box-list-row-head">
                <span class="box-list-row-head-txt">分类:</span>
              </div>
              <div class="box-list-row-end"></div>
            </div>
            <div class="box-list-row">
              <div class="box-list-row-head">
                <span class="box-list-row-head-txt">价格:</span>
              </div>
            </div>
            <div class="box-list-row">
              <div class="box-list-row-head">
                <span class="box-list-row-head-txt">积分:</span>
              </div>
            </div>
          </div> -->
          <!-- 分类结束 -->
          <div class="desc-asc">
            <div class="desc-asc-box">
              <span>综合排序</span>
              <span>新品优先</span>
              <span>销量</span>
              <p>
                <img
                  class="desc-asc-img"
                  src="../assets/images/groupby.png"
                  alt=""
                />
              </p>
              <span>价格</span>
              <p>
                <img
                  class="desc-asc-img"
                  src="../assets/images/groupby.png"
                  alt=""
                />
              </p>
            </div>
          </div>

          <div class="goodlist-list">
            <div
              class="goodlist-list-box"
              @click="toMessage(item)"
              v-for="(item, index8) in GoodByList"
              :key="index8"
            >
              <div class="goodlist-list-box-box">
                <div class="goodlist-include-box">
                  <img class="goodlist-list-img" :src="item.mainImage" alt="" />
                </div>
                <p class="goodlist-list-txt">{{ item.name }}</p>
                <div class="price-row">
                  <span class="price-a">￥</span>
                  <span class="price-b">{{ item.price }}</span>
                  <span class="price-a">.00</span>
                </div>
                <div class="goodlist-bottom" @click.stop="toMessage(item)">
                  <div class="goodlist-bottom-left">
                    <span>查看详情</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pagenavation">
            <!-- <el-pagination
              class="goodlist-pagenavation"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination> -->
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      currentPage4: "",
      name: "",
      GoodByList: [],
      messageList: [],
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      productNum: "1", //商品购买数量
    };
  },
  methods: {
    getRouteData() {
      this.GoodByList = JSON.parse(this.$route.query.name);
      this.name = JSON.parse(this.$route.query.key);
    },
    // 获取购物车信息
    async getshopnum() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList?type=1",
      });
      if (res.data.data) {
        this.$store.commit(
          "altershopnum",
          res.data.data.cartProductVoList.length
        );
      }
    },
    // 获取购物车信息
    async getshopnums() {
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList?type=2",
      });
    },
    async toMessage(item) {
      const res = await this.$http({
        method: "get",
        url: "front/product/findById?id=" + item.id,
      });
      if (res.data.data) {
        this.messageList = res.data.data;
        this.$router.push({
          path: "/goodMessage",
          query: { name: JSON.stringify(this.messageList) },
        });
      }
    },
  },
  created() {
    this.getRouteData();
    this.getshopnum();
    this.getshopnums();

  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#el-container {
  background-color: #f4f4f4;
  /* height: 8000px; */
}
#el-main {
  overflow-y: hidden;
  width: 100%;
  min-width: 1000px;
  padding: 0 0;
  position: relative;
  margin-top: 160px;
}
.goodlist-box {
  width: 100%;
  height: 100%;
}
.goodlist-cate-box {
  display: flex;
  width: 100%;
  height: 35px;
  border-bottom: 3px solid red;
}
.cate-box-div {
  font-size: 14px;
  font-weight: 600;
}
.all-good-list {
  width: 200px;
  background-color: #e33333;
  height: 100%;
  margin-left: 100px;
}
.all-good-list-span {
  min-width: 150px;
  text-align: center;
  line-height: 35px;
  color: white;
}
.all-good-list-right p {
  padding: 0px 30px 0px 30px;
  margin-left: 20px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 700;
}
.all-good-list-right {
  display: flex;
  min-width: 380px;
  margin-left: 10px;
  margin-top: 3px;
}
.search-company {
  display: flex;
  width: 50%;
  height: 40px;
  margin-left: 100px;
  margin-top: 20px;
}
.search-company div:nth-child(1) {
  min-width: 50px;
}
.search-company-img {
  width: 10px;
  height: 10px;
  margin-top: 8px;
}
.search-company-box {
  display: flex;
  min-width: 220px;
  width: 220px;
  height: 28px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #e3e4e5;
  margin-left: 5px;
}
.search-good-fam {
  font-size: 12px;
}
.no-img {
  width: 18px;
  height: 18px;
  margin-left: 40px;
  margin-top: 5px;
}
.search-company-box span {
  padding-top: 5px;
  padding-left: 13px;
}

.shai-xuan {
  min-width: 600px;
  width: 90%;
  height: 100px;
  background-color: #e3e4e5;
  margin: auto;
  display: flex;
}
.company-logo-box {
  flex: 1.6;
  min-width: 150px;
  width: 200px;
  height: 80px;
}
.company-logo-box-img {
  width: 100%;
  height: 100%;
  margin-left: 10%;
  margin-top: 5%;
}
.company-name-txt {
  flex: 2;
  min-width: 200px;
  margin-left: 40px;
  margin-top: 9px;
}
.go-shop-home {
  flex: 6;
  width: 100px;
  height: 40px;
  display: flex;
  line-height: 120px;
  flex-direction: row-reverse;
  margin-top: 30px;
  margin-right: 5%;
  text-align: center;
}
.shop-home-go {
  border-radius: 3px;
  background-color: #e33333;
  width: 100px;
  display: inline-block;
  color: white;
  line-height: 40px;
}
.shai-xuan-box {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 120px;
  margin: auto;
  /* background-color: #e33333; */
  margin-top: 20px;
  border-top: 1px solid #aaa;
}
.box-list-row {
  display: flex;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #aaa;
}
.box-list-row-head {
  width: 130px;
  height: 100%;
  background-color: #e3e4e5;
}
.box-list-row-head-txt {
  color: #666;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  margin-left: 31%;
}
.box-list-row-end span {
  font-size: 12px;
  line-height: 40px;
  padding-right: 60px;
  margin-left: 10px;
  color: #0d4469;
}
.desc-asc {
  width: 76%;
  margin: 0 auto;
  height: 50px;
  border: 1px solid #aaa;
  background-color: #e3e4e5;
  margin-top: 20px;
}
.desc-asc-box {
  display: flex;
  width: 40%;
  height: 100%;
  margin-left: 10px;
  margin-top: 14px;
}
.desc-asc-box span {
  padding-right: 25px;
  font-size: 14px;
}
.desc-asc-img {
  width: 15px;
  height: 15px;
  margin-left: -23px;
  padding-right: 20px;
}
.goodlist-list {
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  width: 77%;
  min-width: 800px;
  /* height: 1500px; */
  margin: 0 auto;
  margin-top: 20px;
}
.goodlist-list-box {
  width: 25%;
  min-width: 200px;
  /* height: 340px; */
}
.goodlist-list-box-box {
  width: 85%;
  margin: 10px;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
}
.goodlist-list-box-box:hover {
  box-shadow: darkgrey 0px 2px 10px 1px;
  /* height: 350px; */
}
.goodlist-include-box {
  width: 80%;
  height: 160px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-list-img {
  width: 100%;
  height: 180px;
  border-radius: 5px;
  /* margin-left: 22%;
    margin-top: 12%; */
}
.goodlist-list-txt {
  width: 90%;
  margin: auto;
  font-size: 13px;
  margin-top: 35px;
  margin-left: 15px;
}
.goodlist-list-txt:hover p {
  color: rgb(123, 123, 224);
}
.price-row {
  margin-top: 12px;
  margin-left: 5px;
}
.price-row span {
  color: #e4393c;
  font-size: 20px;
}
.goodlist-bottom {
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 50px;
  margin-top: 15px;
}
.goodlist-bottom:hover {
  border-radius: 4px;
  transform: scale(1.02);
}
.goodlist-bottom-left {
  border-radius: 4px;
  width: 1000%;
  height: 100%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  background: #e1251b;
}
.goodlist-bottom-left span {
  color: #fff;
}
/* .goodlist-bottom-left:hover span {
  color: rgb(123, 123, 224);
} */
.goodlist-bottom-left span {
  line-height: 50px;
}
/* .goodlist-bottom-right {
  width: 25%;
  height: 100%;
  background-color: #df3033;
  text-align: center;
  line-height: 45px;
}
.goodlist-bottom-right > span {
  color: #fff;
  font-size: 55px;
  font-weight: 100;
} */

.pagenavation {
  width: 50%;
  height: 50px;
  /* background-color: pink; */
  margin-left: 45%;
  margin-top: 4%;
}
.goodlist-pagenavation {
  margin-left: 240px;
}
</style>